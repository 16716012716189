@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap'); */
/* @import '~slick-carousel/slick/slick.css'; */
/* @import '~slick-carousel/slick/slick-theme.css'; */

body {
  font-family: 'Open Sans', sans-serif;
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-size: 16px;
  font-weight: normal;
}

/* .slick-list {
  overflow: visible !important;
} */

/* .title {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 700;
} */

.line {
  display: block;
  width: 100%;
  height: 4px;
  background: #fff;
  background: linear-gradient(90deg,#fff,#fff 39%,#ff0012);
}

.line-bottom {
  display: block;
  width: 100%;
  height: 8px;
  background: #fff;
  background: linear-gradient(90deg,#fff,#fff 39%,#ff0012);
}

.line-custom {
  height: 8px;
  background:linear-gradient(-90deg, white 0%, white 50%, #ff0012 100%);
}

a {
  color: black !important;
  text-decoration: none !important;
  background-color: none !important;
}

.logo {
  width: 200px;
  margin-top: -5px;

}
.navbar-gradient {
  background-color: #3e1e26;
  padding-top: 0;
}

.bg-gradient {
  background-color: #6f3241;
  padding-top: 0;
  height: 120px;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}


.footer {
  margin-top: 300px;
  background-color: #111;
}

.footer img {
  width: 300px;
  margin-top: -8px;
}


.footer-menu a {
  list-style: none;
  color: #df691a !important;
}

.footer-menu {
  list-style: none;
  color: #df691a !important;
}

.navbar-gradient p {
  color: hsla(0,0%,100%,.5);
  font-weight: 700;
  margin-bottom: 0;
  display: inline-block;
  padding: 16px;
  text-transform: uppercase;
}

.flex-even {
  flex: 1 1
}

/* .slick-track {
  width: 100% !important;
} */

.card-list {
  position: relative;
  display: -webkit-box;
  flex: 1 1;
  width: 100%;
  min-height: 348px;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 10px;
  transition: all .3s;
  padding: 16px;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
}

.card-list:hover {
  cursor: pointer;
  box-shadow: 0 2px 6px 3px rgba(0,0,0,.2);
  transition: box-shadow 0.3s ease-in-out;
}

.wrap-tags {
  position: absolute;
  bottom: 16px;
  right: 16px;
  /* margin: 0 16px 16px 16px; */
}

.lazy-load-image-background {
  width: 100% !important;
}

.img-landscape {
  width: 100%;
  height: 200px;
  transition: all 0.2s ease-out;
  margin: -30px auto 0 auto;
  border-radius: 10px;
  object-fit: cover;
  object-position: 50% 50%;
  margin-bottom: 16px;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.img-landscape:hover {
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in-out; /** Chrome & Safari **/
  -moz-transition: all 0.2s ease-in-out; /** Firefox **/
  -o-transition: all 0.2s ease-in-out; /** Opera **/
  /* margin: -5px auto 8px auto; */
  box-shadow: 0px 6px 20px -7px rgba(0,0,0,0.75);
}

.img-banner {
  width: 100%;
  height: 880px;
  background: linear-gradient(206deg, rgba(13,13,13,0.4570027840237657) 0%, rgba(194,0,0,0.4822128680573792) 100%), url(/static/media/new_banner.4aaba2a5.jpeg);
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
}

.banner-child {
  width: 100%;
  height: 300px;
  background: linear-gradient(163deg, rgba(13,13,13,0.4570027840237657) 0%, rgba(194,0,0,0.4822128680573792) 100%), url(/static/media/header.bfd71837.jpg);
  background-repeat: no-repeat, repeat;
  background-size: cover;
  /* background-position: 50% 35%; */
}

.banner-child-2 {
  width: 100%;
  height: 800px;
  /* background: linear-gradient(163deg, rgba(13,13,13,0.4570027840237657) 0%, rgba(194,0,0,0.4822128680573792) 100%), url('../src/assets/images/banner2.jpg'); */
  background: url(/static/media/banner2.a16aa375.jpg);
  background-repeat: no-repeat, repeat;
  background-size: cover;
  /* background-position: 50% 35%; */
}

/* Chrome, Safari, Edge, Opera */
.input-costume input::-webkit-outer-spin-button,
.input-costume input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-costume input[type=number] {
  -moz-appearance: textfield;
}

.form-check-label {
  color: #5e646b !important;
}

.m-30 {
  margin-top: 45px;
}

.mb-32 {
  margin-bottom: 32px;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
/* .banner-child h1,p {
  text-shadow: 1px 1px #dccbcb;
} */

.btn-danger {
  background-color: #ef2336 !important;
}

.color-grey {
  color: #847f7f;
}

.color-orange {
  color: #df691a;
  font-weight: 600;
}

.img-gallery {
  width: 100%;
}

.input-costume {
  /* border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 1px solid #e8bcbc; 
  box-shadow: none;
  padding-left: 0;
  margin-bottom: 36px; */  
  margin-top: 8px
}

.input-costume:focus { 
  outline: none !important;
  /* border-bottom: 2px solid  #df691a; */
  box-shadow: none;
  border: 2px solid  #df691a;
}

.input-group-text {
  background-color: transparent;
  border: none;
}
.table {
  overflow: hidden;
}
.table thead th {
  text-align: center;
  background-color: #df691a;
  vertical-align: middle;
  border: 1px solid black;
}

.table tbody tr td {
  padding: 5px;
  border: 1px solid black;
}

.btn-outline-secondary:hover {
  color: #6c757d;
  background: white;
  border-color: #6c757d;
}

/* .text-overflow {
  max-lines: 4;
  white-space: nowrap;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  max-height: 3.6em; 
} */

.text-overflow {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative; 
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.61em; 
  margin-right: -1em;
  padding-right: 1em;
}

.nav-item {
  padding: 10px;
  margin-top: -10px;
}

.bg-dark {
  background-color: #000000 !important;
}

.navbar-mobile {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  grid-gap: 8px;
  grid-gap: 8px;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #000000 !important
}

.nav-link :active {
  color: red;
}

.text-shadow {
  text-shadow: 2px 2px 4px #8a0f0f;
}

@media (min-width: 992px) {
  .container-fluid {
    width: 1440px !important; 
  }
}

@media scree and (max-width: 1024px) {
  .container-fluid {
    widows: 100% !important;
  }
}

@media screen and (max-width: 460px) {
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .img-banner {
    height: 300px;
    background-repeat: no-repeat, repeat;
    background-size: cover;
  }
  .video {
    height: 300px;
    margin-bottom: 26px;
  }
  h1, h2, h3 {
    font-size: 22px;
  }
  h4, h5, h6 {
    font-size: 16px;
  }
  .m-4 {
    margin-top: 20px;
  }
  .banner-child-2 {
    max-width: 100%;
    height: auto;
    background: url(/static/media/b.002c532f.png);
    /* background: url('../src/assets/images/banner2.jpg'); */
    
    background-repeat: no-repeat, repeat;
    background-size: cover;
    background-position: 0 0;
  }
}

/* input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: yellow;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid yellow;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em red;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
} */

/* For WebKit-based browsers like Chrome and Safari */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
